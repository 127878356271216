import { baseEnvironment } from './environment.base';
import type { Environment } from './environment';
import { overwriteEnvironment } from './environment.overwrite';

export const environment: Environment = {
  ...baseEnvironment,
  production: false,
  environmentName: 'dev1',
  serviceUrl: 'https://studio.svc.dev1.screencastify.com/api/studioService/',
  httpHasuraUrl:
    'https://studio-graphql.svc.dev1.screencastify.com/v1/graphql/',
  websocketHasuraUrl:
    'ws://studio-graphql.svc.dev1.screencastify.com/v1/graphql/',
  dataDogClientToken: 'pube2463a15a229b89ea1735dcf5e95e901',
  dataDogApplicationId: '155c4e0f-15c8-475d-ba2d-53d902d1bcb3',
  googleAuth: {
    scopes: ['https://www.googleapis.com/auth/drive.file'],
    clientId:
      '741471751866-vlfbvujeo7de30i720k40lu5gqcpkmj8.apps.googleusercontent.com',

    driveAppId: '741471751866', // staging
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCVUsVSR0zKizsDXlvTKwXbzKFDqbyp574',
    authDomain: 'castify-storage-dev1.firebaseapp.com',
    databaseURL: 'https://castify-storage-dev1.firebaseio.com',
    projectId: 'castify-storage-dev1',
    storageBucket: 'castify-storage-dev1.appspot.com',
    messagingSenderId: '611231039500',
    appId: '1:611231039500:web:107f73f419cf6f611fae29',
  },
  externalLinks: {
    accountLink: 'https://app.dev1.screencastify.com/account',
    myVideoLink: 'https://account.screencastify.com/user/launch/',
    supportLink:
      'https://learn.screencastify.com/hc/en-us/categories/360003891334-Edit',
  },
  // overwrite url endpoints
  ...overwriteEnvironment,
};
